@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

body {
    color: #fff;
}

@layer utilities {
    /*new*/
    .all-caption{
        @apply text-xs font-inter font-normal uppercase;
    }
    .caption{
        @apply text-sm font-inter font-normal;
    }
    .body-4-reg{
        @apply font-normal font-inter leading-normal;
    }
    .body-4-bold{
        @apply text-base font-bold font-inter leading-normal;
    }
    .body-3-reg{
        @apply text-lg font-normal font-inter leading-normal;
    }
    .body-3-bold{
        @apply text-lg font-bold font-inter leading-normal;
    }
    .body-2-reg{
        @apply text-xl font-normal font-inter leading-normal;
    }
    .body-2-bold{
        @apply text-xl font-bold font-inter leading-normal;
    }
    .body-1-reg{
        @apply text-[22px] font-normal font-inter leading-normal;
    }
    .body-1-bold{
        @apply text-[22px] font-bold font-inter leading-normal;
    }
    .header-3{
        @apply text-2xl font-bold font-inter;
    }
    .header-2{
        @apply text-3xl font-bold font-inter;
    }
    .header-1{
        @apply text-4xl font-bold font-inter;
    }
    .hero-3{
        @apply text-2xl font-dela;
    }
    .hero-2{
        @apply text-4xl font-dela;
    }
    .hero-1{
        @apply text-[48px] font-dela;
    }
}
